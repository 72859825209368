/** Classes for the displayed toast **/
.Toastify__toast-container {
    word-break: break-word
}
.Toastify__toast {
    border-radius: 5px;
    text-align: center;
}
.Toastify__toast--success {
    background-color: #4caf50;
}
.Toastify__toast--warning {
    background-color: #ff9800;
}
.Toastify__toast--error {
    background-color: #f44336;
}